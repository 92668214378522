import "@fontsource/roboto"
import React from "react"
import "../index.css";
import Header from '../../components/header';
import { Typography } from "@material-ui/core";

//@ts-ignore
import spencer from '../spencer.jpeg';

export default function Home() {
  return (
  <Header>
    <div style={{textAlign: 'center', marginTop: '20px'}}>
      <Typography variant="h3">
          About the Man, the Myth, the Legend
      </Typography>
        <div>
        <img style={{height: '150px', borderRadius:'100px'}} src={spencer}/>
        </div>
      <div style={{maxWidth:'800px', display:'inline-block', textAlign:'left'}}>
        <p>
            So, you want to know more about me? Well, get ready for a long ramble because you CHOSE to read this. If at any point you stop reading, please know that I am grateful you read as far as you did.
        </p>
        <p>
            I guess you want to know a little about me or maybe about this site. Let's start with the site. I created this site because I love learning and sharing my knowledge. When I was a college student, I was a TA for a computer science class. One of my favorite parts about that job was helping the students who came in for help understand the material. I always did my best to give just enough for them to start pulling things together themselves. And when the solved the problem, I felt very proud knowing that I was able to help them acheive that. So, I guess I hope this site helps people learn and / or gets more people excited about learning!
        </p>
        <p>
            A little more about me: I love learning as you can probably tell. I spend a lot of time coming up with dumb little projects and hacking stuff together. The site you're looking at now is a product of this. Some other stuff about me, I love playing video games. Here are my top games that I'd highly suggest playing:
            <li>Persona 5</li>
            <li>Neir Automata</li>
            <li>Undertale</li>
        </p>
        <p>
            Still here? Wow, ok. You must really be invested in either me, or this site. Either way, I appreciate it very much! Alright, you can know my favorite projects on this site (so far):
            <li>Connect 4</li>
            <li>Travelling Sales Person</li>
            <li>Autoencoders</li>
        </p>
      </div>
    </div>
  </Header>
  );
}
